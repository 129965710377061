import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import UserProfile from "./UserProfile";
import Subscribition from "./Subscribition";

export default class SubscribitionRoutes extends Component {
  render() {
    return <div>
        <Routes>
        <Route path="/" Component={Subscribition} />
        <Route path="/userprofile" Component={UserProfile} />
        </Routes>
    </div>;
  }
}