import "./App.css";
import SubscribitionRoutes from "./pages/SubscribitionRoutes";


function App() {
  return (
    <div className="App">
      <SubscribitionRoutes />
    </div>
  );
}

export default App;
