import React, { Component } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "../withRouter";
import SubscribitionCard from "../components/SubscribitionCard";
import Swal from "sweetalert2";
import "../App.css";
import { Navigate, redirect } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { configuration } from "../appConfig";

const cookies = new Cookies();
export class Subscribition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan_code: "",
      data: [],
      data1: [],
      errors: {},
      isLoading: false,
      isLoader: false,
      show3: false,
      show2: true,
      input: {},
      input1: {},
      show: false,
    };
    this.handleClear = this.handleClear.bind(this);
  }
  async componentDidMount() {
    if (this.state.input1.subscription_id || this.state.mobile) {
      this.state.show2 = false;
      await this.getSubscribitionDetails();
    } else {
      this.state.show2 = true;
    }
    cookies.set("currency_code", "INR");
    cookies.set("organizationid", "60019725969");
  }

  handleClear() {
    cookies.remove("subscription_id");
    cookies.remove("display_name");
    cookies.remove("mobile");
    cookies.remove("email");
    this.props.router.navigate("/");
    window.location.reload();
  }

  getSubscribitionDetails = () => {
    this.state.isLoader = true;
    let url = configuration.get_all_plans;

    axios
      .get(`${url}?currency_code=${cookies.get("currency_code")}`)
      .then((res) => {
        console.log(res.data,"data1")
        this.setState({
          data: res.data,
          isLoader: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  handleClose = () => {
    this.setState({
      show: false,
      isLoading: false,
    });
  };

  handlePayment = () => {
    this.setState({ isLoading: true });

    let url = configuration.subscribe_to_plan;

    const body = {
      plan: {
        plan_code: this.state.plan_code,
        billing_cycles: 1,
      },

      redirect_url: "https://tourwiz.demopod.in/userprofile",
      // redirect_url: "http://localhost:3001/userprofile",
    };
    if (this.state.customer_id) {
      body.customer_id = this.state.customer_id;
    } else {
      body.customer = {
        email: this.state.input.email,
        display_name: this.state.input.display_name,
        mobile: this.state.input.mobile,
      };
    }

    axios
      .post(url, body)
      .then((res) => {
        this.setState({ isLoading: false });
        window.open(res.data.url, "_self");
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          isLoading: false,
        });
      });
  };

  regestrionClick = () => {
    this.setState({
      show2: false,
      show3: true,
    });
  };
  LoginClick = () => {
    this.setState({
      show2: true,
      show3: false,
    });
  };

  handleChange1 = (e) => {
    let input1 = this.state.input1;
    input1[e.target.name] = e.target.value;
    this.setState({ input1 });
  };
  handleChange2 = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ input });
  };

  get_details_of_current_subscription = () => {
    let url = configuration.get_details_of_current_subscription;

    axios
      .get(`${url}?subscription_id=${cookies.get("subscription_id")}`)
      .then((res) => {
        if (!res.data.error) {
          this.setState({ isLoading: false });
          this.props.router.navigate("/userprofile");
        } else {
          this.setState({ isLoading: false });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "SubscriptionId is invalid",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };
  regesterClick = async (e) => {
    e.preventDefault();

    if (this.validate()) {
      let input = {};
      input["mobile"] = "";
      input["email"] = "";
      input["display_name"] = "";
      cookies.set("mobile", this.state.input.mobile);
      cookies.set("email", this.state.input.email);
      cookies.set("display_name", this.state.input.display_name);
      this.setState({
        show3: false,
      });
      this.state.input.email = cookies.get("email");
      this.state.input.mobile = cookies.get("mobile");
      this.state.input.display_name = cookies.get("display_name");

      await this.getSubscribitionDetails();
    }
  };

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Mobile Number.";
    }

    if (typeof input["mobile"] !== "undefined") {
      var pattern = new RegExp(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
      );
      if (!pattern.test(input["mobile"])) {
        isValid = false;
        errors["mobile"] = "Please Enter Number Only";
      } else if (input["mobile"].length != 10) {
        isValid = false;
        errors["mobile"] = "Please enter valid  Mobile Number.";
      }
    }
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your Email .";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please Enter Valid Email";
      }
    }
    if (!input["display_name"]) {
      isValid = false;
      errors["display_name"] = "Please enter your display_name .";
    }

    if (typeof input["display_name"] !== "undefined") {
      var pattern = new RegExp(/^[a-zA-Z0-9]/);
      if (!pattern.test(input["display_name"])) {
        isValid = false;
        errors["display_name"] = "Please Enter Valid display_name";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  continueClick = async (e) => {
    e.preventDefault();

    if (this.validate1()) {
      this.setState({ isLoading: true });
      let input1 = {};
      input1["subscription_id"] = "";

      cookies.set("subscription_id", this.state.input1.subscription_id);
      this.setState({
        show3: false,
      });
      this.state.input1.subscription_id = cookies.get("subscription_id");

      await this.get_details_of_current_subscription();
    }
  };

  validate1() {
    let input1 = this.state.input1;
    let errors = {};
    let isValid = true;
    if (!input1["subscription_id"]) {
      isValid = false;
      errors["subscription_id"] = "Please enter your Subscription Id.";
    }

    if (typeof input1["subscription_id"] !== "undefined") {
      var pattern = new RegExp(/^[0-9]/);
      if (!pattern.test(input1["subscription_id"])) {
        isValid = false;
        errors["subscription_id"] = "Please Enter Valid Subscription Id";
      } else if (input1["subscription_id"].length != 19) {
        isValid = false;
        errors["subscription_id"] = "Please enter valid  Subscription Id.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  setData = (res) => {
    this.setState({
      data1: res.data,
      plan_code: res.data.plan_code,
      show: true,
    });
  };

  render() {
    const { isLoading, isLoader } = this.state;
    console.log(this.state?.data,
      "this.state?.data")
    return (
      <>
        {isLoader ? (
          <div className="loader">
            <i
              className="fa fa-circle-o-notch fa-spin main-loader"
              style={{ marginRight: "5px" }}
            />
          </div>
        ) : (
          <div>
            {this.state.show2 == false && this.state.show3 == false ? (
              <header className="border-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="Dropdown">
                        <DropdownButton
                          id="dropdown-variants-Secondary"
                          title="Business Name"
                        >
                          <Dropdown.Item onClick={this.handleClear}>
                            Sign Out
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            ) : (
              <header></header>
            )}

            <div className="tw-plan-header" style={{ margin: "25px auto" }}>
              <div className="container">
                <div className="row">
                  {Object.keys(this.state?.data)?.map((objKey, i) => {
                    return (
                      <SubscribitionCard
                        details={this.state.data[objKey]}
                        key={i}
                        setData={(data) => this.setData(data)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <Modal
              onHide={this.handleClose}
              size="lg"
              centered
              show={this.state.show}
            >
              <Modal.Header closeButton>
                <Modal.Title>Payment Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row mb-4">
                  <div className="col-9 mb-2">
                    <i>
                      <b>
                        Your plan selection : {this.state.name}
                        {this.state.data1.interval_unit}
                      </b>
                    </i>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <div className="row ">
                        <div className="col-7">Subscribition Start Date : </div>
                        <div className="col-5 text-right">
                          {this.state.data1.subscriptionStartDate}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-7">Subscribition End Date: </div>
                        <div className="col-5 text-right">
                          {this.state.data1.subscriptionEndDate}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="border-top mt-2"></div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-7">Plan Value : </div>
                        <div className="col-5 text-right">
                          {this.state.data1.finalAmount}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-7">Discount : </div>
                        <div
                          className="col-5 text-right"
                          style={{ color: "green" }}
                        >
                          ₹0
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-7">GST : </div>
                        <div className="col-5 text-right">
                          {this.state.data1.GST}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="border-top mt-2"></div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-7">
                          <b className="text-primary">Total : </b>
                        </div>
                        <div className="col-5 text-right">
                          <b className="text-primary">
                            {this.state.data1.netAmount}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button
                      className="btn btn-primary mt-4 mb-2"
                      style={{ minWidth: "40%" }}
                      onClick={this.handlePayment}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <i
                          className="fa fa-circle-o-notch fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        <span>Pay Now</span>
                      )}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={this.state.show2} size="lg" centered>
              <Modal.Header>
                <Modal.Title>Continue With Subscription Id</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row mt-2">
                  <div className="col-lg-12">
                    <div className="input">
                      <b>Subscription Id: </b>
                      <input
                        type="text"
                        name="subscription_id"
                        value={this.state.input1.subscription_id}
                        onChange={this.handleChange1}
                        style={{ width: "60%" }}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors.subscription_id}
                    </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-12 text-center">
                    <button
                      className="btn btn-primary mt-2 mb-2 "
                      style={{ minWidth: "40%" }}
                      onClick={this.continueClick}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <i
                          className="fa fa-circle-o-notch fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        <span>Continue</span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-12 text-center">
                    Not A Member <span></span>
                    <button
                      className="btn btn-link text-primary p-0 m-0 pl-1 mb-1 font-weight-bold"
                      onClick={this.regestrionClick}
                    >
                      Click Here
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={this.state.show3} size="lg" centered>
              <Modal.Header>
                <Modal.Title>Register Here</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 ">
                    <div>
                      <div>
                        <b>Name: </b>
                      </div>
                      <input
                        type="text"
                        name="display_name"
                        value={this.state.input.display_name}
                        onChange={this.handleChange2}
                        style={{ width: "60%" }}
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors.display_name}
                    </span>
                    <div className="mt-2">
                      <div>
                        <b>Email: </b>
                      </div>

                      <input
                        type="text"
                        name="email"
                        value={this.state.input.email}
                        onChange={this.handleChange2}
                        style={{ width: "60%" }}
                      />
                    </div>

                    <span style={{ color: "red" }}>
                      {this.state.errors.email}
                    </span>

                    <div className="mt-2">
                      <div>
                        <b>Mobile :</b>
                      </div>
                      <input
                        type="text"
                        name="mobile"
                        value={this.state.input.mobile}
                        onChange={this.handleChange2}
                        style={{ width: "60%" }}
                      />
                    </div>

                    {
                      <span style={{ color: "red" }}>
                        {this.state.errors.mobile}
                      </span>
                    }
                    <div className="row mt-3">
                      <div className="col-lg-12 text-center">
                        <button
                          className="btn btn-primary mb-2"
                          style={{ minWidth: "40%" }}
                          onClick={this.regesterClick}
                        >
                          Register Here
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-12 text-center">
                    Continue With Subscription Id <span></span>
                    <button
                      className="btn btn-link text-primary p-0 m-0 pl-1 mb-1 font-weight-bold"
                      onClick={this.LoginClick}
                    >
                      Click Here
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Subscribition);
