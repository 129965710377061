import axios from "axios";
import React, { Component } from "react";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";
import { configuration } from "../appConfig";

const cookies = new Cookies();
export class SubscribitionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  handleClick = (plan_code) => {
    cookies.remove("transactionId");

    this.setState({ isLoading: true });
    
    let url;
    let baseUrl = configuration.details_before_checkout;
    if (this.props.subscriptionId != undefined) {
      url = `${baseUrl}?plan_code=${plan_code}&subscriptionid=${this.props.subscriptionId}`;
    } else {
      url = `${baseUrl}?plan_code=${plan_code}`;
    }
    axios
      .get(url)
      .then((res) => {
        this.props.setData(res);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  render() {
    const { name, interval_unit, recurring_price, plan_code, interval } =
      this.props.details;
    const { isLoading } = this.state;
    return (
      <div className="col-lg-3 col-md-6 pr-0 mt-3">
        <div className="tw-plan-box WEBSITE">
          <h2>{name}</h2>
          <h3>
            <p>{`${cookies.get("currency_code")}` === "INR" ? "₹" : "$"}</p>
            {recurring_price}
            <span>
              /{interval != "1" ? interval : ""}
              <span></span>
              {interval == "1"
                ? interval_unit.substring(0, interval_unit.length - 1)
                : interval_unit}
            </span>
            {/* <span>/{(interval)!="1"?(interval):""}<span></span>{interval_unit}</span> */}
          </h3>
          <button
            className="btn btn-primary"
            disabled={this.props.dis}
            onClick={() => {
              this.setState({
                plan_code: plan_code,
                name: name,
              });
              this.handleClick(plan_code);
            }}
          >
            {isLoading ? (
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              <span>
                {this.props.dis === true ? "Current Plan" : "Subscribe to Plan"}
              </span>
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default SubscribitionCard;
