import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { withRouter } from "../withRouter";
import { Cookies } from "react-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import SubscribitionCard from "../components/SubscribitionCard";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { configuration } from "../appConfig";

const cookies = new Cookies();
export class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1: [],
      data2: [],
      isLoader: false,
      isLoading: false,
      customer_id: cookies.get("customer_id"),
      hostedpage_id: "",
    };
    this.handleClear = this.handleClear.bind(this);
  }
  async componentDidMount() {
    let search = this.props.router.location.search;
    const hostedpage_id = search.split("=");
    // const transactionId = search.split("=");

    cookies.set("transactionId", hostedpage_id[1]);

    if (cookies.get("transactionId") == "undefined") {
      await this.get_details_of_current_subscription();
      await this.getSubscribitionDetails();
    } else {
      this.setState({
        hostedpage_id: hostedpage_id[2],
      });
      cookies.set("currency_code", "INR");
      cookies.set("hostedpage_id", hostedpage_id[2]);

      await this.getUserProfile();
      await this.getSubscribitionDetails();
    }
  }

  get_details_of_current_subscription = () => {
    let url = configuration.get_details_of_current_subscription;

    axios
      .get(`${url}?subscription_id=${cookies.get("subscription_id")}`)
      .then((res) => {
        this.setState({
          data1: res.data.data?.subscription,
          status: res.data.data.message,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Enter Your SubscriptionId",
          showConfirmButton: false,
          timer: 1500,
        });
        this.props.router.navigate("/");
      });
  };

  async handleClear() {
    cookies.remove("subscription_id");
    cookies.remove("display_name");
    cookies.remove("mobile");
    cookies.remove("email");

    cookies.remove("customer_id");
    cookies.remove("hostedpage_id");
    cookies.remove("transactionId");
    this.props.router.navigate("/");
    // window.location.reload('/');
  }
  getUserProfile = () => {
    this.state.isLoader = true;
    // let url = `http://192.168.0.102:8000/allApis/details_after_payment?hostedpage_id=${cookies.get(
    //   "hostedpage_id"
    // )}`;
    let url = configuration.details_after_payment;

    axios
      .get(`${url}?hostedpage_id=${cookies.get("hostedpage_id")}`)
      .then((res) => {
        this.setState({
          data1: res.data.data?.subscription,
          status: res.data.status,
          isLoader: false,
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Payment successful",
          showConfirmButton: false,
          timer: 1500,
          isLoading: false,
          coustmor: {
            email: cookies.get("email", "testCustmor@gmail.com"),
            display_name: cookies.get("display_name", "testCustmor"),
            mobile: cookies.get("mobile", "7572837646"),
          },
        });
        cookies.set(
          "subscription_id",
          res.data.data?.subscription?.subscription_id
        );
        cookies.set("hostedpage_id", res.data.hostedpage_id);

        cookies.set(
          "customer_id",
          res.data.data?.subscription?.customer_id
        );
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  handleClose = () => {
    this.setState({
      show: false,
      isLoading: false,
    });
  };
  getSubscribitionDetails = () => {
    let url = configuration.get_all_plans;

    axios
      .get(`${url}?currency_code=${cookies.get("currency_code")}`)
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ isLoader: false });
        this.props.router.navigate("/");
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  setData = (res) => {
    this.setState({
      data2: res.data,
      plan_code: res.data.plan_code,
      EndDate: res.data?.endDateOfCurrentSubscription.slice(0, 10),
      show: true,
    });
  };

  handlePayment = () => {
    this.setState({ isLoading: true });

    let url = configuration.updateFinal;
    const body = {
      subscription_id: cookies.get("subscription_id"),
      plan: {
        plan_code: this.state.plan_code,
        billing_cycles: 1,
      },
      redirect_url: "https://tourwiz.demopod.in/userprofile",
      // redirect_url: "http://localhost:3001/userprofile",
    };

    axios
      .post(url, body)
      .then((res) => {
        if (res.data.url) {
          window.open(res.data.url, "_self");
        } else {
          this.setState({ isLoading: false, show: false, data1: res.data });
          cookies.set("subscription_id", res.data?.subscription_id);
          this.get_details_of_current_subscription();
          this.getSubscribitionDetails();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Subscription Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something Went Wrong",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          isLoading: false,
        });
      });
  };
  render() {
    const { isLoading, EndDate } = this.state;
    const date = new Date().toJSON().slice(0, 10);

    return (
      <>
        {this.state.isLoader ? (
          <div className="loader">
            <i
              className="fa fa-circle-o-notch fa-spin main-loader"
              style={{ marginRight: "5px" }}
            />
          </div>
        ) : (
          <div>
            <header className="border-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="Dropdown">
                      <DropdownButton
                        id="dropdown-variants-Secondary"
                        title="Business Name(Piyush Senjaliya)"
                      >
                        <Dropdown.Item onClick={this.handleClear}>
                          Sign Out
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="body row  mt-4 mb-4">
              <div className="col-lg-4 "></div>
              {this.state.status === "success" ? (
                <div
                  className="p-3 col-lg-4 mb-3 shadow-sm"
                  style={{ background: "rgb(241, 130, 71)" }}
                >
                  <h4 className="mb-3 text-center ">Current Plan Details</h4>

                  <h5>Plan Name:{this.state.data1?.plan?.name}</h5>
                  <p className="mb-3">
                    Valid Till:{this.state.data1.expires_at}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <div>
                <div className="tw-plan-header" style={{ margin: "0 auto" }}>
                  <div className="container">
                    <div className="row">
                      {Object.keys(this.state?.data)?.map((objKey, i) => {
                        return (
                          <SubscribitionCard
                            details={this.state.data[objKey]}
                            key={i}
                            subscriptionId={this.state.data1?.subscription_id}
                            setData={(data) => this.setData(data)}
                            dis={
                              this.state.data1?.plan?.name ===
                              this.state.data[objKey].name
                                ? true
                                : false
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {this.state.data2?.creditsUtilized === undefined ? (
                  <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Payment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row mb-4">
                        <div className="col-9 mb-2">
                          <i>
                            <b>
                              Your plan selection : {this.state.name}
                              {this.state.data2.interval_unit}
                            </b>
                          </i>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div>
                            <div className="row ">
                              <div className="col-7">
                                Subscribition Start Date :{" "}
                              </div>
                              <div className="col-5 text-right">{date}</div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">
                                Subscribition End Date:{" "}
                              </div>
                              <div className="col-5 text-right">{EndDate}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="border-top mt-2"></div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-7">Plan Value : </div>
                              <div className="col-5 text-right">
                                {this.state.data2?.finalAmount}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">GST : </div>
                              <div className="col-5 text-right">
                                {this.state.data2?.GST}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">Applied Credit : </div>
                              <div className="col-5 text-right">
                                {"-"}
                                {this.state.data2?.unusedCreditsBeforeNewPlan}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="border-top mt-2"></div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">
                                <b className="text-primary">Total : </b>
                              </div>
                              <div className="col-5 text-right">
                                <b className="text-primary">
                                  {this.state.data2.netAmount}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <button
                            className="btn btn-primary mt-4 mb-2"
                            style={{ minWidth: "40%" }}
                            onClick={this.handlePayment}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ) : (
                              <span>Pay Now</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Payment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row mb-4">
                        <div className="col-9 mb-2">
                          <i>
                            <b>
                              Your plan selection : {this.state.name}
                              {this.state.data2.interval_unit}
                            </b>
                          </i>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div>
                            <div className="row ">
                              <div className="col-7">
                                Subscribition Start Date :{" "}
                              </div>
                              <div className="col-5 text-right">{date}</div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">
                                Subscribition End Date:{" "}
                              </div>
                              <div className="col-5 text-right">{EndDate}</div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="border-top mt-2"></div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-7">Plan Value : </div>
                              <div className="col-5 text-right">
                                {this.state.data2?.finalAmount}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">GST : </div>
                              <div className="col-5 text-right">
                                {this.state.data2?.GST}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">Cradit Utilized : </div>
                              <div className="col-5 text-right">
                                {"-"}
                                {this.state.data2?.creditsUtilized}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">
                                Cradits used for extending subscription :{" "}
                              </div>
                              <div className="col-5 text-right">
                                {"-"}
                                {this.state.data2?.creditsLeft}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="border-top mt-2"></div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-7">
                                <b className="text-primary">Total : </b>
                              </div>
                              <div className="col-5 text-right">
                                <b className="text-primary">{"00"}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <button
                            className="btn btn-primary mt-4 mb-2"
                            style={{ minWidth: "40%" }}
                            onClick={this.handlePayment}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ) : (
                              <span>Subscribe to Plan</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(UserProfile);
